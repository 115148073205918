import React, { FC, useContext, RefObject } from 'react';
import { Typography, Skeleton } from 'antd';
import LayoutUI from './LayoutUI';
import UnmappedFieldsUI from './UnmappedFieldsUI';
import { FormContext } from '../../providers/FormProvider';
const { Title, Text } = Typography;

interface FormUIProps {
  contentRef: RefObject<HTMLDivElement>;
}

const FormUI: FC<FormUIProps> = React.memo(({ contentRef }) => {
  const {
    selectedForm,
    selectedFormVersion,
    formTypeOfSelectedForm,
    formVersionAccess,
  } = useContext(FormContext);

  const handleTitleChange = (newTitle: string) => {
    if (selectedForm.data && formVersionAccess?.canWrite && newTitle !== selectedForm.data.title) {
      selectedForm.debouncedSet({ title: newTitle });
    }
  };

  if (!selectedForm.data || !selectedFormVersion.data || !formTypeOfSelectedForm.data?.defaultUILayout) {
    return <Skeleton active />;
  }

  const { defaultUILayout } = formTypeOfSelectedForm.data;

  if (!defaultUILayout.structure) {
    return <Text>Error: No UI layout defined for this form.</Text>;
  }

  return (
    <div
      ref={contentRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        gap: '2px',
      }}
    >
      <Title
        level={3}
        style={{
          width: '100%',
          marginTop: 0,
          marginBottom: 5,
        }}
        editable={{ onChange: handleTitleChange }}
      >
        {selectedForm.data.title || 'Untitled Form'}
      </Title>
      <LayoutUI
        uiElement={defaultUILayout.structure}
        itemData={selectedFormVersion.data}
      />
      <UnmappedFieldsUI
        uiElement={defaultUILayout.structure}
        itemData={selectedFormVersion.data}
      />
    </div>
  );
});

FormUI.displayName = 'FormUI';

export default FormUI;