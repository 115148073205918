/**
 * SYSTEM EVENT LOG TYPES
 * Defines the data structure of the system event log.
 */
// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { TimeType, DocId, Meta } from "./System.types";

/**
 * Represents an event log entry in the system.
 * Is a DB document.
 * Stored in the collection `eventLogs/`.
 */
export type EventLogEntry = {
    /** Unique identifier for the log entry 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Timestamp of when the event occurred */
    timestamp: TimeType;
    /** Type of the event */
    agentType: AgentType;
    /** Category of the event */
    eventCategory: EventCategory;
    /** Description of the event */
    description: string;
    /** Associated form ID, if applicable */
    formId?: string;
    /** Associated user ID, if applicable */
    userId?: string;
    /** Changed values, if any */
    changedValues?: {
        [key: string]: {
            oldValue: any;
            newValue: any;
        };
    };
    /** Indicates if the event is an error */
    isError?: boolean;
    /** Additional metadata for the event */
    meta?: Meta;
};

/**
 * Enum representing the types of events that can be logged.
 * Is a Enum field.
 */
export enum AgentType {
    /** Represents a human user */
    User = "User",
    /** Represents an AI language model agent */
    LLMAgent = "LLMAgent",
    /** Represents a scheduling agent */
    Scheduler = "Scheduler",
    /** Represents the system itself */
    System = "System"
}

/**
 * Enum representing the categories of events.
 * Is a Enum field.
 */
export enum EventCategory {
    /** Event related to creating a new form */
    FormCreation = "FormCreation",
    /** Event related to updating an existing form */
    FormUpdate = "FormUpdate",
    /** Event related to changing the state of a form */
    FormStateChange = "FormStateChange",
    /** Event related to archiving a form */
    FormArchive = "FormArchive",
    /** Event related to system maintenance tasks */
    SystemMaintenance = "SystemMaintenance",
}